import { FULFILLMENT_TYPE } from '@dehaat/kisan-app-bl/models/Vendor';
import { ReactNode, RefObject, useContext } from 'react';
import { CartContext } from '@/context/CartProvider';
import { UserContext } from '@/context/UserProvider';
import { useAppSelector } from '@/hooks/reduxHooks';
import useIsMobile from '@/hooks/useIsMobile';
import { Product } from '@/models/Product';
import { selectLatLng } from '@/store/features/locationSlice';
import { ProductListTypeEnum } from '@/utils/analytics/types';
import { isArray } from '@/utils/helper';
import ProductCardLoading from './ProductCardLoading';
import ProductListingCard from './ProductListingCard';
interface Props {
  products: Product[];
  loading: boolean;
  cartEmpty: boolean;
  nextLoaderRef?: RefObject<HTMLDivElement>;
  noResults?: ReactNode;
  listType: ProductListTypeEnum;
  hideOutOfStockLabel?: boolean;
  showSeeDetailsCTA?: boolean;
  pageType?: 'search' | 'product';
}
const ProductResults = ({
  products,
  loading,
  cartEmpty,
  nextLoaderRef,
  noResults,
  listType,
  hideOutOfStockLabel = false,
  showSeeDetailsCTA = false,
  pageType
}: Props) => {
  const hasSearchedProducts = isArray(products);
  const freshLoad = products?.length === 0 && loading;
  const isLoadingNext = hasSearchedProducts && loading;
  const latLng = useAppSelector(selectLatLng);
  const {
    user
  } = useContext(UserContext);
  const {
    currentVendor
  } = useContext(CartContext);
  const {
    isMobile,
    isWindowLoading
  } = useIsMobile();
  const loadingCards = <div className={`grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-2 p-2 ${pageType === 'search' ? 'lg:grid-cols-3' : ''}`}>
      <ProductCardLoading count={isMobile && !isWindowLoading ? 8 : 12} />
    </div>;
  const loadingNext = isLoadingNext ? loadingCards : <div className="p-2" ref={nextLoaderRef} />;
  const showEditPrice = currentVendor?.fulfillment_type === FULFILLMENT_TYPE.PICKUP_FROM_STORE && user?.is_variant_price_editable;
  const showSearchResults = hasSearchedProducts ? <div className={`w-full ${cartEmpty ? '' : 'pb-16'}`}>
      <div className={`grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-2 p-2  ${pageType === 'search' ? 'lg:grid-cols-3' : ''}`}>
        {products.map((product, index) => <div key={`${product.id}-${index}`}>
            <ProductListingCard key={product.id} product={product} position={index} listType={listType} hideOutOfStockLabel={hideOutOfStockLabel || latLng == null} showSeeDetailsCTA={showSeeDetailsCTA} showEditPrice={showEditPrice} dcId={currentVendor?.entity_id} />
          </div>)}
      </div>
      {nextLoaderRef ? loadingNext : null}
    </div> : <>{noResults}</>;
  return freshLoad ? loadingCards : showSearchResults;
};
export default ProductResults;