import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import ProductIcon from '../icons/ProductIcon';
interface Props {
  imageUrl: string | null;
  productName: string;
  isOutOfStock?: boolean;
  imageClassName?: string;
  fallbackClassName?: string;
  outOfStockImageClassName?: string;
}
const ProductListingImage = ({
  imageUrl,
  productName,
  isOutOfStock = false,
  imageClassName,
  outOfStockImageClassName,
  fallbackClassName = 'h-16 w-16'
}: Props) => {
  const {
    t
  } = useTranslation('products');
  return <div className={`aspect-square p-2 shrink-0 grow-0 relative flex items-center justify-center pb-1 ${imageClassName}`} data-sentry-component="ProductListingImage" data-sentry-source-file="ProductListingImage.tsx">
      {imageUrl ? <Image src={imageUrl} alt={productName} width={80} height={80} className={`object-contain ${isOutOfStock && 'opacity-50'}`} style={{
      width: 'auto',
      height: '100%'
    }} /> : <ProductIcon className={fallbackClassName} />}
      {isOutOfStock && <div className={`absolute text-[10px] bg-opacity-75 flex items-center justify-center left-0 top-2 ${outOfStockImageClassName} `}>
          <span className="bg-[#5A6066] py-1 px-4 rounded-2xl text-white text-center">
            {t('out_of_stock')}
          </span>
        </div>}
    </div>;
};
export default ProductListingImage;