import { formatCurrency } from '@dehaat/dds';
import useTranslation from 'next-translate/useTranslation';
import useIsMobile from '@/hooks/useIsMobile';
import { Product, ProductVariant } from '@/models/Product';
import { ProductListTypeEnum } from '@/utils/analytics/types';
import Paragraph, { VARIANT as PARAGRAPH_VARIANT } from '../Paragraph';
import HyperLocalAddToCart from './HyperLocalAddToCart';
import InsuranceLabel from './InsuranceLabel';
// import ProductImage from './ProductImage'
import ProductListingImage from './ProductListingImage';
interface Props {
  productVariant: ProductVariant;
  listType?: ProductListTypeEnum;
  position?: number;
}
const ProductVariantCard = ({
  productVariant,
  listType,
  position
}: Props) => {
  const {
    t
  } = useTranslation('products');
  const {
    isMobile
  } = useIsMobile();
  const {
    name: productName,
    image_url: productImage
  } = (productVariant.product as Product);
  const hasInsurance = productVariant.insuranceData ? productVariant.insuranceData.hasInsurance : false;
  const discount = Number(productVariant?.inventory_set[0].discount);
  const price = Number(productVariant?.inventory_set[0].price);
  const discountPercent = Math.round(discount / price * 100);
  return <section className={`bg-white border-neutral-10 rounded shadow-[0_4px_4px_rgba(0,0,0,0.05)]  pr-3 w-full flex items-center ${hasInsurance ? 'pb-[27px]' : ''}`} data-sentry-component="ProductVariantCard" data-sentry-source-file="ProductVariantCard.tsx">
      <div className="flex items-center">
        <div className="flex flex-col items-center relative">
          <ProductListingImage imageUrl={productImage} productName={productName} imageClassName="h-20 w-20" fallbackClassName="h-8 w-8" outOfStockImageClassName="h-20 w-20" data-sentry-element="ProductListingImage" data-sentry-source-file="ProductVariantCard.tsx" />
          {hasInsurance ? <div className="absolute" style={{
          top: 'calc(100% - 1px)'
        }}>
              <InsuranceLabel />
            </div> : null}
        </div>
        <Paragraph variant={PARAGRAPH_VARIANT.BIG} className="text-[#33393D] shrink-0 grow-0 ml-3 font-medium" data-sentry-element="Paragraph" data-sentry-source-file="ProductVariantCard.tsx">
          {productVariant.attribute_value[0].name}
        </Paragraph>
      </div>
      <div className="flex flex-1 flex-row gap-1 items-center justify-center lg:justify-start lg:ml-6">
        {discount > 0 && !isMobile ? <>
            <p className={`font-nato-semibold text-neutral-80  text-center`}>
              {formatCurrency(price - discount)}
            </p>
            <span className=" flex flex-row text-ellipsis line-through ml-1">
              {formatCurrency(price)}
            </span>

            <p className="text-[#43A314] text-xs font-medium ml-1">
              ({discountPercent}% {t('sale_off')})
            </p>
          </> : <p className={`font-nato-semibold text-neutral-80  text-center`}>
            {formatCurrency(price)}
          </p>}
      </div>
      <HyperLocalAddToCart productVariant={productVariant} listType={listType} index={position} isDrawerVisible={true} data-sentry-element="HyperLocalAddToCart" data-sentry-source-file="ProductVariantCard.tsx" />
    </section>;
};
export default ProductVariantCard;