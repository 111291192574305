import { Product, ProductVariant } from '@/models/Product';
import { ProductListTypeEnum } from '@/utils/analytics/types';
import Caption, { VARIANT as CAPTION_VARIANT } from '../Caption';
import Paragraph, { VARIANT } from '../Paragraph';
import ProductVariantCard from './ProductVariantCard';
interface Props {
  productVariants: ProductVariant[];
  listType?: ProductListTypeEnum;
  position?: number;
  onContine?: VoidFunction;
}

/**
 * A component for listing all different product variants.
 * The list currently opens in a bottom drawer.
 *
 * Design: https://www.figma.com/file/7ufg3Mz9SvODeu33zuRBtp/React-Revamp?node-id=1249%3A36142&t=fHSIDHxQqjAi81bk-1
 */
const ProductVariantList = ({
  productVariants,
  listType,
  position,
  onContine
}: Props) => {
  const {
    brand,
    name: productName
  } = (productVariants[0].product as Product);
  return <>
      <header>
        {brand ? <Caption variant={CAPTION_VARIANT.ONE} className="font-nato-medium text-neutral-50" label={brand.name} /> : null}
        <Paragraph variant={VARIANT.BIG} isHighlighted className="text-neutral-90" data-sentry-element="Paragraph" data-sentry-source-file="ProductVariantList.tsx">
          {productName}
        </Paragraph>
      </header>
      <section className="mt-4 flex flex-col gap-3">
        {productVariants.map(variant => <ProductVariantCard key={variant.id} productVariant={variant} listType={listType} position={position} />)}
      </section>
      <button className="py-3 mt-4 w-full flex flex-1 bg-primary-100 rounded-lg justify-center items-center" onClick={() => onContine?.()}>
        <p className="text-lg text-white font-medium">Continue</p>
      </button>
    </>;
};
export default ProductVariantList;