import ButtonLabel, { VARIANT } from '../ButtonLabel';
import MinusIcon from '../icons/MinusIcon';
import PlusIcon from '../icons/PlusIcon';
interface Props {
  onAdd: VoidFunction;
  onRemove: VoidFunction;
  quantity: number;
  btnContainerStyle?: string;
  disableAdd?: boolean;
  disableRemove?: boolean;
  isDrawerVisible?: boolean;
}
const HyperLocalAddRemoveButton = ({
  onAdd,
  onRemove,
  quantity,
  btnContainerStyle = 'bg-primary-20 border-primary-110',
  disableAdd = false,
  disableRemove = false,
  isDrawerVisible = false
}: Props) => <div className={`rounded-lg border py-2.5 px-3 text-primary-100 flex  items-center justify-between gap-2  mt-2.5 ${btnContainerStyle} ${isDrawerVisible ? 'w-32' : 'flex-1 w-full'}`} onClick={e => {
  e.stopPropagation();
  e.preventDefault();
}} data-sentry-component="HyperLocalAddRemoveButton" data-sentry-source-file="HyperLocalAddRemoveButton.tsx">
    <button onClick={onRemove} data-testid="cart-minus" className={disableRemove ? 'opacity-20' : undefined} disabled={disableRemove}>
      <MinusIcon className="fill-transparent stroke-primary-100" height={20} width={20} data-sentry-element="MinusIcon" data-sentry-source-file="HyperLocalAddRemoveButton.tsx" />
    </button>
    <ButtonLabel variant={VARIANT.TWO} className="font-nato-semibold text-primary-110 text-center" label={quantity} data-sentry-element="ButtonLabel" data-sentry-source-file="HyperLocalAddRemoveButton.tsx" />
    <button onClick={onAdd} data-testid="cart-plus" disabled={disableAdd} className={disableAdd ? 'opacity-20' : undefined}>
      <PlusIcon className="fill-transparent stroke-primary-100" height={20} width={20} data-sentry-element="PlusIcon" data-sentry-source-file="HyperLocalAddRemoveButton.tsx" />
    </button>
  </div>;
export default HyperLocalAddRemoveButton;